import {
	model, Model, prop, idProp,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';
import { STATUS_MESSAGE } from '~/util/messaging/line-item-status/statusMessage.constants';

interface MessageValues {
	salesText?: string
	dropDate?: string
}

@model(`${modelNamespace.UTIL}/Messaging/StatusMessageModel`)
export class StatusMessageModel extends Model({
	id: idProp,
	messageKey: prop<STATUS_MESSAGE>(),
	messageValues: prop<MessageValues | undefined>(),
}) {
}
