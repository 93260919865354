'use client';

import dynamic from 'next/dynamic';
import type { MouseEvent, ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

import { ContactUsFormViewState } from '~/contact-us/Components/ContactUsForm';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { isOnServer, s7ImagePath, s7ContentPath } from '~/global/global.constants';
import { S7Image } from '~/components/Images/S7Image';

import styles from '#/components/magic-modal/magic-modal-here-to-help.module.scss';

const SiteMessaging = dynamic(() => import('~/global/site-messaging/SiteMessaging').then(mod => mod.SiteMessaging), { ssr: false });

const HereToHelpModalBody = (props: { contactUsFormStore: any }) => {
	const {
		liveChat,
	} = useGlobalContext();

	const { contactUsFormStore } = props;

	const [showContactForm, setShowContactForm] = useState(false);

	const [showChatIcon, setShowChatIcon] = useState(liveChat?.showChatIcon);

	function toggleContactUsForm() {
		setShowContactForm(!showContactForm);
	}

	function openChat() {
		liveChat.openChat();
	}

	useEffect(() => {
		if (isOnServer) {
			return;
		}
		setShowChatIcon(liveChat?.showChatIcon);
	});

	return (
		<div data-tr-link-event-comp-name="were here to help" data-tr-link-event-comp-type="modal">
			<div className="tw-flex tw-items-start md:tw-gap-3">
				<div className="md:tw-w-[42%]">
					<div className="Heading Heading--5 tw-font-normal tw-text-gray-100">
					Located in our Minneapolis headquarters, our expert Customer Experience Specialists are ready to assist you with everything from design ideas and product recommendations to delivery details and service questions.
					</div>
					<ul className="tw-mt-6">
						<li className="tw-mb-2"><strong>Monday – Friday</strong> 8 a.m. to 7 p.m. (CT)</li>
						<li><strong>Saturday – Sunday</strong> 10 a.m. to 5 p.m. (CT)</li>
					</ul>
					<div className={styles['modal-site-messaging']}>
						<SiteMessaging filter="HOLIDAY_HOURS" />
					</div>
				</div>
				<div className="tw-hidden lg:tw-block">
					<S7Image
						alt=""
						height={311}
						priority={false}
						src={`${s7ImagePath}/header_help_da3_0724`}
						width={395}
					/>
				</div>
			</div>
			<div className={styles['call-to-action-container']}>
				<div className={`${styles['call-to-action']} ${styles['call']}`}>
					<a href="tel:800.301.9720">
						<img className={styles['action-icon']} src={`${s7ContentPath}/icon_phone`} alt="" />
						<div>
							<div className={styles['action-label']}>Call Us</div>
							800.301.9720
						</div>
					</a>
				</div>
				<div className={`${styles['call-to-action']} ${styles['email']}`}>
					<button
						aria-controls="contact-us-form"
						aria-expanded={showContactForm}
						className="ButtonAnchor"
						data-qa="contact-us-modal-email-us-btn"
						onClick={toggleContactUsForm}
					>
						<img className={styles['action-icon']} src={`${s7ContentPath}/icon_email`} alt="" />
						Email Us
					</button>
				</div>
				{
					showContactForm &&
					<div
						id="contact-us-form"
						className={
							`${styles['call-to-action']} ${styles['emailForm']} formz tw-border-l-0 tw-border-r-0`
						}
					>
						<ContactUsFormViewState contactUsFormStore={contactUsFormStore} />
					</div>
				}
				<div className={`${styles['call-to-action']} ${styles['chat']}`}>
					{
						showChatIcon
							?
							<button
								className="ButtonAnchor"
								onClick={openChat}
							>
								<img className={styles['action-icon']} src={`${s7ContentPath}/icon_chat`} alt="" />
							Chat now
							</button>
							:
							<div>
								<p>
									<img
										className={styles['action-icon']}
										src={`${s7ContentPath}/icon_chat`}
										alt=""
									/>
									Chat
								</p>
								<p className={styles['live-chat-offline-text']}>CURRENTLY NOT AVAILABLE</p>
							</div>
					}
				</div>
			</div>
		</div>
	);
};

interface Props {
	dataQa?: string
	children?: ReactNode
	className?: string
	contactUsFormStore: any
}
export const HereToHelpModal = (props: Props) => {
	const {
		magicModal,
	} = useGlobalContext();
	const {
		contactUsFormStore,
		className = 'tw-hidden md:tw-inline print:tw-hidden',
		dataQa = 'whth-button',
	} = props;

	function handleClick(event: MouseEvent<HTMLButtonElement>) {
		contactUsFormStore.init(true);
		magicModal.openModal({
			id: 'here-to-help-modal',
			showHeader: true,
			showCloseButton: true,
			title: 'We’re Here to Help',
			maxWidth: '750px',
			width: '80vw',
			content: {
				children: (
					<HereToHelpModalBody contactUsFormStore={contactUsFormStore} />
				),
			},
			containerClass: styles['here-to-help-modal'],
		}, event);
	}

	return (
		<button className={className} data-qa={dataQa} onClick={handleClick}>
			{
				props.children ||
				<>
					<span className="tw-mr-4">We're Here to Help</span>
					<span className="tw-mr-4"><img src={`${s7ContentPath}/icon_phone`} alt="" width="24" /></span>
					<span className="tw-mr-4"><img src={`${s7ContentPath}/icon_email`} alt="" width="24" /></span>
					<span><img className="" src={`${s7ContentPath}/icon_chat`} alt="" width="24" /></span>
				</>
			}
		</button>
	);
};
