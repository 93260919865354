import React, { useEffect } from 'react';

import Head from '~/layout/head/Components/Head';
import Breadcrumbs from '~/layout/breadcrumbs';
import { HereToHelpModal } from '~/components/magic-modal/Components/HereToHelpModal';
import { useSignInContext } from '~/account/sign-in/page/Contexts/SignIn.context';
import { SignInContent } from '~/account/sign-in/Components/SignInContent';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const SignInLayout = () => {
	const {
		breadcrumbs,
		contactUsFormStore,
		oneTrustStore,
	} = useSignInContext();

	const {
		globalDynamicModel: {
			gpcSignal = false,
		} = {},
	} = useGlobalContext();

	useEffect(() => {
		// wait for oneTrust and Adobe Launch
		setTimeout(() => {
			oneTrustStore.handleGPCSignal(gpcSignal);
		}, 500);
	});

	return (
		<>
			<Head
				title="Sign In - Room & Board"
				description="Room & Board - Sign In"
				url="/account/sign-in/"
			/>

			<div className="tw-mb-3 tw-flex tw-justify-between">
				<Breadcrumbs breadcrumbModels={breadcrumbs} />
				<HereToHelpModal contactUsFormStore={contactUsFormStore} />
			</div>

			<SignInContent />
		</>
	);
};
