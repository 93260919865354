/* eslint-disable import/no-duplicates */

import React, {
	createContext, useContext, ReactNode,
} from 'react';

// Duplicate import needed to address TypeScript's import elision
// See https://devblogs.microsoft.com/typescript/announcing-typescript-3-8/#type-only-imports-exports
import '~/favorites/list/Stores/FavoriteList.store.root';
import type { ContactUsFormStore } from '~/contact-us/Stores/contactUsForm.store';
import { ContactUsFormStoreFactory } from '~/contact-us/Stores/contactUsForm.store';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { BreadcrumbModelFactory, breadcrumbsType } from '~/layout/Models/Breadcrumb.model';
import { SignInStore } from '~/account/sign-in/Stores/SignIn.store';
import { OneTrustStore } from '~/account/sign-in/Stores/OneTrust.store';

interface SignInContext {
	store: any,
	breadcrumbs: breadcrumbsType,
	contactUsFormStore: ContactUsFormStore,
	oneTrustStore: OneTrustStore,
}

const Context = createContext({} as SignInContext);

export const SignInContextProvider = (
	{ children }: { children: ReactNode }
) => {
	const {
		globalDynamicStore,
		HREF,
	} = useGlobalContext();

	const oneTrustStore = new OneTrustStore({});

	const store = SignInStore(
		null,
		{
			globalDynamicStore,
			globalDynamicModel: globalDynamicStore.model,
			HREF,
		},
		{ isInModal: false },
		{},
		oneTrustStore,
	);
	const breadcrumbs = BreadcrumbModelFactory.create({ id: 10, title: 'Sign In', url: '/account/sign-in/' }, 10);
	const contactUsFormStore = ContactUsFormStoreFactory.create();

	return (
		<Context.Provider value={{
			store,
			breadcrumbs,
			contactUsFormStore,
			oneTrustStore,
		}}>
			{children}
		</Context.Provider>
	);
};

export const useSignInContext = () => useContext(Context);
