import {
	model, Model, prop, idProp,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';
import { AVAILABILITY_MESSAGE } from '~/util/messaging/availability/availabilityMessage.constants';
import { IMessage } from '~/account/management/Views/quotes/Interfaces/QuoteDetail.interface';

interface MessageValues {
	availabilityDate?: string
	leadTime?: string
	originalAvailableDate?: string
}

@model(`${modelNamespace.UTIL}/Messaging/AvailabilityMessageModel`)
export class AvailabilityMessageModel extends Model({
	id: idProp,
	messageKey: prop<AVAILABILITY_MESSAGE>(),
	messageValues: prop<MessageValues | undefined>(),
}) {
	get availabilityDate() {
		return this.messageValues?.availabilityDate || '';
	}

	get leadTime() {
		return this.messageValues?.leadTime || '';
	}

	get originalAvailableDate() {
		return this.messageValues?.originalAvailableDate || '';
	}

	static createList(json: IMessage[] | undefined) {
		return json?.map(item => AvailabilityMessageModel.create(item));
	}

	static create(json: IMessage) {
		const {
			messageKey,
			messageValues = undefined,
		} = json;

		return new AvailabilityMessageModel({
			messageKey,
			messageValues,
		});
	}
}
