import React from 'react';
import { observer } from 'mobx-react';
import NextLink from 'next/link';
import classNames from 'classnames';

import styles from './sign-in-content.module.scss';
import stylesAcc from '#/account/sign-in/sign-in.module.scss';

import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { s7ImagePath } from '~/global/global.constants';
import { useSignInContext } from '~/account/sign-in/page/Contexts/SignIn.context';
import { SignInForm } from '~/account/sign-in/Components/SignInForm';

export const SignInContent = observer(() => {
	const {
		store,
		store: {
			isSubmitted,
			isSubmitProcessing,
		} = {},
	} = useSignInContext();

	const isProcessing = isSubmitted || isSubmitProcessing;

	return (
		<>
			{
				isProcessing && (
					<div className={styles.spinnerContainer} key="create-form-spinner">
						<LoadingSpinner isLoading parentSettings={{ width: '100%', height: '500px' }} />
					</div>
				)
			}
			{
				!isProcessing && (
					<div className="tw-py-6 ajax-submit-wrapper create-account tw-relative" key="create-form" data-qa="sign-in-page-content">
						<div className="tw-grid tw-gap-y-6 tw-grid-cols-1 md:tw-grid-cols-2">
							<div className="form-section">
								<h1 className="tw-heading-3">
									Welcome Back <span className="tw-sr-only"> - Sign In</span>
								</h1>
								<div className={styles.formIntro}>Please sign in with your account information.</div>
								<SignInForm store={store} />
							</div>

							<div className={classNames(stylesAcc['info-section'], 'info-section')}>
								<h2 className="tw-heading-3">Create an Account</h2>
								<div className={styles.createAccountBlurb}>A Room & Board account lets you:</div>
								<ul className={styles.infoList} style={{ listStyleImage: `url(${s7ImagePath}/biPage21_checkmark?wid=18)` }}>
									<li>Check out faster</li>
									<li>Save to Favorites</li>
									<li>Track current and past orders</li>
									<li>Schedule delivery</li>
									<li>Make a payment</li>
								</ul>

								<NextLink
									className="Button"
									data-qa={'create-an-account-button'}
									href="/account/create"
								>
									Create an Account
								</NextLink>
							</div>
						</div>
					</div>
				)
			}
		</>
	);
});
