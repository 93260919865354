import React from 'react';

import { withPagePromise } from '~/global/app-config/withPagePromise';
import { PrimaryLayout } from '~/layout/Components/PrimaryLayout';
import { SignInContextProvider } from '~/account/sign-in/page/Contexts/SignIn.context';
import { SignInLayout } from '~/account/sign-in/Layout/SignInLayout';

const SignIn = () => {
	return (
		<PrimaryLayout>
			<SignInContextProvider>
				<SignInLayout />
			</SignInContextProvider>
		</PrimaryLayout>
	);
};

export default SignIn;

export const getServerSideProps = withPagePromise(async (pageProps) => {
	return {
		props: {
			globalDynamicData: pageProps.globalDynamicData,
		}
	};
});
